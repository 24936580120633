.error {

  .error__message {
    text-align: center;
    margin-bottom: 10px;
  }

  .error__message--note {
    text-align: center;
    margin-bottom: 30px;

    a {
      font-weight: $medium;
      color: $black;
    }
  }

  .error__image {
    border-radius: 50%;
    display: block;
    margin: 0 auto;
    width: 50%;
  }
}