//Font
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;

//Project colors
$white: #FFFFFF;
$black: #000000;
$main-blue: #345AE7;
$table-blue: #404D80;

$main-bc: #F9FBFD;

$grey-1: #C0C2CC;
$grey-2: #B8BCCC;
$grey-3: #EFF2F5;
$grey-4: #808A9D;

$graph-tooltip-bg: #26313c; 

$green: #16C784;
$red: #EA3943;

//Responsive breakpoints
//example: @media only screen and (max-width: $tablet-bp) {}
$tablet-bp: 768px;
$mobile-bp: 500px;
$big-bp: 1920px;

//grid width percentages
$grid-width-standard: 75%;
$grid-width-mobile: 95%;
$grid-width-mobile--filter: 90%;
$grid-width-big: 60%;