// Font import
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

//Variables
@import 'variables';

//Component css imports
@import 'navigation';
@import 'footer';
@import 'filter';
@import 'table';
@import 'trending';
@import 'detail/detail-main';
@import 'detail/coin-info';
@import 'detail/coin-stats';
@import 'detail/coin-change';
@import 'not-found';

* {
  font-family: 'Montserrat', sans-serif;
  margin: 0; 
  padding: 0; 
  border: 0; 
  list-style: none;
  text-decoration: none;
}

body {
  background-color: $main-bc;
}

.main {
  width: $grid-width-standard;
  margin: 45px auto;
  min-height: 90vh;

  @media only screen and (max-width: $tablet-bp) {
    width: $grid-width-mobile
  }

  @media only screen and (min-width: $big-bp) {
    width: $grid-width-big;
    margin: 50px auto;
  }

  .header {
    font-size: 35px;
    font-weight: $semi-bold;

    @media only screen and (max-width: $tablet-bp) {
      font-size: 30px;
    } 
    
    @media only screen and (min-width: $big-bp) {
      font-size: 40px;
    }
  }

  .header__note {
    font-size: 15px;
    font-weight: $regular;
    margin-top: 10px;
    max-width: 600px;

    @media only screen and (max-width: $tablet-bp) {
      font-size: 13px;
    }

    @media only screen and (min-width: $big-bp) {
      font-size: 17px;
    }
  }
}