.coin {
  position: relative;

  .coin-text {
    margin-top: 20px;
    width: 80%;
    position: relative;

    @media only screen and (max-width: $tablet-bp) {
      width: 100%;
    }

    .coin-text__header {
      margin-bottom: 10px;
      font-size: 30px;
      font-weight: $medium;
    }

    .coin-text__paragraph {
      font-size: 15px;
      line-height: 25px;
      max-height: 300px;
      overflow: hidden;
      position: relative;

      @media only screen and (min-width: $big-bp) {
        font-size: 17px;
        line-height: 30px;
      }

      a {
        color: $black;
        font-weight: $semi-bold;
        text-decoration: underline;
      }
    }

    .coin-text__fade {
      margin-top: -100px;
      height: 100px;
      background-image: linear-gradient(to top, rgba(249,251,253, 1) 0%, rgba(249,251,253, 0) 100%);
      z-index: 30;
      position: relative;
    }

    .fade--off {
      display: none;
    }

    .open {
      max-height: 9999px;
    }

    .coin-text__more {
      font-size: 15px;
      font-weight: $semi-bold;
      color: $main-blue;
      width: 110px;
      margin-top: 5px;
      cursor: pointer;
      position: relative;

      .arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      .arrow--less {
        transform: translateY(-50%) rotate(-180deg);
      }
    }
  }

  .flex {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    @media only screen and (max-width: $tablet-bp) {
      flex-direction: column;
    }
  }

  .graph {
    margin: 50px 0 20px 0;
    width: 75%;

    @media only screen and (max-width: $tablet-bp) {
      width: 100%;
    }

    .graph__header {
      font-weight: $medium;
      font-size: 30px;
      margin-bottom: 10px;
    }

    .change-history {
      width: 130px;
      height: 40px;
      margin-bottom: 20px;
      border-radius: 5px;
      cursor: pointer;
      padding-left: 20px;
      font-weight: $medium;
      appearance: none;
      background: url('../../img/arrow_down.svg') no-repeat right $white;
      -webkit-appearance: none;
      background-position-x: 100px;
      -webkit-box-shadow: 0px 3px 0px 0px $grey-1;
      -moz-box-shadow: 0px 3px 0px 0px $grey-1; 
      box-shadow: 0px 3px 0px 0px $grey-3;

      @media only screen and (min-width: $big-bp) {
        height: 45px;
        width: 140px;
        font-size: 15px;
      }
    }

    .tooltip {
      border-radius: 3px;
      background: $graph-tooltip-bg;
      color: $white;
      padding: 15px;
      box-shadow: 5px 10px 40px 5px rgba(0,0,0,0.5);
      text-align: center;
    }
  }
}