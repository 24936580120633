.filter {
  background-color: $white;
  width: 100%;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(250,250,250,1);
  -moz-box-shadow: 0px 4px 4px 0px rgba(250,250,250,1);
  box-shadow: 0px 4px 4px 0px rgba(250,250,250,1);

  .filter__wrapper {
    width: $grid-width-standard;
    margin: 0 auto;
    padding: 70px 0;

    @media only screen and (max-width: $tablet-bp) {
      width: $grid-width-mobile--filter;
      padding: 20px 0 20px 0;
    }

    @media only screen and (min-width: $big-bp) {
      width: $grid-width-big;
    }
  }

  .search {
    position: relative;
    width: 96%;

    .search__input {
      position: relative;
      color: $grey-2;
      font-weight: $semi-bold;
      font-size: 19px;
      width: 100%;
      height: 70px;
      padding-left: 40px;
      background-color: $main-bc;
      border-radius: 8px;
      box-shadow: 0px 3px 0px 0px rgba(239,242,245,1);

      @media only screen and (max-width: $tablet-bp) {
        height: 55px;
        font-size: 15px;
        padding-left: 20px;
      }

      @media only screen and (min-width: $big-bp) {
        font-size: 21px;
      }

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $grey-2;
        opacity: 1; /* Firefox */
      }
      
      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $grey-2;
      }
      
      &::-ms-input-placeholder { /* Microsoft Edge */
        color: $grey-2;
      }
    }

    .search__suggestions {
      position: absolute;
      width: 100%;
      margin-top: 3px;
      z-index: 20;

      .search__link {
        color: $black;
        display: none;

        &:nth-child(-n+5) {
          display: block;
        }
      }

      .search__item {
        display: flex;
        flex-direction: row;
        padding: 15px 20px;
        margin-bottom: 2px;
        width: 100%;
        background-color: $main-bc;
        box-shadow: 0px 2px 0px 0px rgba(239,242,245,1);
        border-radius: 5px;
        cursor: pointer;

        &:hover {
          background-color: $white;
        }

        @media only screen and (max-width: $tablet-bp) {
          padding: 10px 10px;
          font-size: 15px;
        }

        .search__item__image {
          height: 25px;
          width: 25px;
        }

        .search__item__name {
          margin-left: 20px;
          align-self: center;
          font-weight: $medium;
        }

        .search__item__symbol {
          margin-left: 20px;
          align-self: center;
          font-weight: $medium;
          color: $grey-1;
        }
      }
    }

    .search__button {
      width: 46px;
      height: 46px;
      background-color: $main-blue;
      border-radius: 8px;
      position: absolute;
      top: 13px;
      right: 0;
      cursor: pointer;
      -webkit-box-shadow: 3px 3px 15px 0px rgba(0,0,0,0.2);
      -moz-box-shadow: 3px 3px 15px 0px rgba(0,0,0,0.2);
      box-shadow: 3px 3px 15px 0px rgba(0,0,0,0.2);   

      @media only screen and (max-width: $tablet-bp) {
        width: 32px;
        height: 32px;
        right: 10px;
      }

      .search__icon {
        fill: $white;
        width: 24px;
        height: 24px;

        @media only screen and (max-width: $tablet-bp) {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  .change-amount {
    width: 130px;
    height: 40px;
    margin-top: 40px;
    border-radius: 5px;
    cursor: pointer;
    padding-left: 20px;
    font-weight: $medium;
    appearance: none;
    background: url('../img/arrow_down.svg') no-repeat right $main-bc;
    -webkit-appearance: none;
    background-position-x: 100px;
    -webkit-box-shadow: 0px 3px 0px 0px rgba(239,242,245,1);
    -moz-box-shadow: 0px 3px 0px 0px rgba(239,242,245,1); 
    box-shadow: 0px 3px 0px 0px rgba(239,242,245,1);

    @media only screen and (max-width: $tablet-bp) {
      margin-top: 30px;
    }

    @media only screen and (min-width: $big-bp) {
      height: 45px;
      width: 140px;
      font-size: 15px;
    }
  }
  
  .change-amount__note {
    color: $grey-1;
    font-size: 10px;
    font-weight: $regular;
    margin-top: 10px;

    @media only screen and (min-width: $big-bp) {
      font-size: 13px;
    }
  }
}
