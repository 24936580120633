.trending {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 45px;
  position: relative;

  @media only screen and (max-width: $tablet-bp) {
    justify-content: center;
  }

  @media only screen and (min-width: $big-bp) {
    margin-top: 50px;
  }

   .trending__item {
     margin: 0 15px 15px 0;
     padding: 20px 0 20px 20px;
     width: 240px;
     height: 80px;
     background-color: $white;
     border-radius: 5px;
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     cursor: pointer;

     @media only screen and (min-width: $big-bp) {
      width: 300px;
      height: 100px;
    }

     &:hover {
       background-color: $main-bc;
     }

     .trending__item__image {
      width: 25px;
      height: 25px;

      @media only screen and (min-width: $big-bp) {
        width: 28px;
        height: 28px;
      }
     }

     .trending__item__name {
       font-size: 15px;
       font-weight: $semi-bold;
       margin-left: 10px;
       margin-top: 2px;
       width: 75%;

       @media only screen and (min-width: $big-bp) {
        font-size: 18px;
        margin-left: 18px;
        margin-top: 3px;
      }
     }

     .trending__item__symbol, .trending__item__rank, .trending__item__score {
       margin-top: 5px;
       margin-right: 7px;
       height: 17px;
       font-size: 10px;
       font-weight: $medium;
       padding: 7px 10px 3px 10px;
       border-radius: 5px;

       @media only screen and (min-width: $big-bp) {
        font-size: 12px;
        height: 20px;
      }
     }

     .trending__item__symbol {
      background-color: $grey-3;
     }

     .trending__item__rank {
      background-color: $grey-4;
      color: $white;
     }

     .trending__item__score {
      background-color: $black;
      color: $white;
     }
   }
}