.footer {
  width: 100%;
  height: 135px;
  background-color: $white;
  color: $grey-1;
  text-align: center;
  -webkit-box-shadow: 0px -4px 4px 0px rgba(250,250,250,1);
  -moz-box-shadow: 0px -4px 4px 0px rgba(250,250,250,1);
  box-shadow: 0px -4px 4px 0px rgba(250,250,250,1);

  .footer__name {
    padding-top: 30px;
    font-size: 17px;
  }

  .footer__note {
    padding-top: 20px;
    font-size: 15px;

    a {
      color: $black
    }
  }
}