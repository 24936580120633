.table__container {
  overflow-x: auto;
  width: 100%;
  
  .table {
    border-collapse: collapse;
    font-size: 15px;
    width: 100%;

    @media only screen and (max-width: $tablet-bp) {
      font-size: 14px;
    }

    @media only screen and (min-width: $big-bp) {
      font-size: 18px;
    }

    .table__head {
       
      .change, .market-cap, .volume, .supply {
        @media only screen and (max-width: $tablet-bp) {
          display: none;
        }
      }

      .sorting__icon {
        width: 15px;
        height: 15px;
        display: none;
      }

      .down {
        transform: rotate(90deg);
        display: inline;
        padding-left: 6px;
      }

      .up {
        transform: rotate(-90deg);
        display: inline;
        padding-right: 6px;
      }
    }

    .table__head, .table__foot {
      color: $grey-2;
      
      tr th {
        font-weight: $semi-bold;
        padding: 15px 0 15px 0;
        text-align: left;
        cursor: pointer;
        position: relative;
      }
    }

    #table-center {
      text-align: center;
    }

    #table-right {
      @media only screen and (max-width: $tablet-bp) {
        text-align: right;
        padding-right: 20px;
      }
    }

    .table-row {
      height: 55px;
      background-color: $white;
      border-bottom: 2px solid $main-blue;
      color: $table-blue;
      font-weight: $medium;
      cursor: pointer;

      @media only screen and (max-width: $tablet-bp) {
        height: 50px;
      }

      @media only screen and (min-width: $big-bp) {
        height: 70px;
      }

      &:hover {
        background-color: $main-bc;
      }

      .coin__market-cap-nr {
        min-width: 35px;
        text-align: center;

        @media only screen and (max-width: $tablet-bp) {
          min-width: 35px;
        }
      }

      .coin__image {
        min-width: 65px;

        @media only screen and (max-width: $tablet-bp) {
          min-width: 40px;
        }
        
        .coin__image-src {
          display: block;
          margin: 0 auto;
          width: 23px;
        }
      }

      .coin__name {
        font-weight: $semi-bold;
        min-width: 120px;

        @media only screen and (max-width: $tablet-bp) {
          min-width: 100px;
        }
      }

      .coin__price {
        min-width: 100px;

        @media only screen and (max-width: $tablet-bp) {
          text-align: right;
          padding-right: 20px;
        }
      }

      .coin__change {
        min-width: 70px;
      }

      .coin__market-cap {
        min-width: 150px;
      }

      .coin__volume {
        min-width: 150px;
      }

      .coin__supply {
        min-width: 160px;
        white-space: nowrap;
      }

      .coin__change, .coin__market-cap, .coin__volume, .coin__supply {

        @media only screen and (max-width: $tablet-bp) {
          display: none;
        }
      }

      .mobile__triangle {
        display: none;
        width: 20px;
        height: 20px;
        position: absolute;
        right: 120px;

        @media only screen and (max-width: $tablet-bp) {
          display: block;
        }
      }

      .mobile__triangle--green {
         transform: rotate(-90deg);
      }

      .mobile__triangle--red {
        transform: rotate(90deg);
      }
    }
  }
}
