.coin {
  .coin-info {
    position: relative;

    .breadcrumbs {
      display: flex;
      font-size: 15px;
      color: $grey-4;

      @media only screen and (max-width: $tablet-bp) {
        font-size: 13px;
      }

      .breadcrumbs__base {
        color: $grey-4;
      }

      .breadcrumbs__spacer {
        padding: 0 10px;
      }

      .breadcrumbs__coin {
        text-transform: uppercase;
      }
    }

    .main-info {
      margin-top: 30px;
      width: 100%;

      .main-head {
        display: flex;
        flex-wrap: wrap;

        .coin__image {
          width: 40px;
          height: 40px;
          display: block;

          @media only screen and (max-width: $tablet-bp) {
            width: 30px;
            height: 30px;
          }

          @media only screen and (min-width: $big-bp) {
            width: 50px;
            height: 50px;
          }
        }

        .coin__name {
          font-size: 35px;
          margin-left: 15px;

          @media only screen and (max-width: $tablet-bp) {
            font-size: 30px;
          }

          @media only screen and (min-width: $big-bp) {
            font-size: 45px;
          }
        }

        .coin__name--background {
          position: absolute;
          font-size: 85px;
          opacity: 0.03;
          top: 20px;
          left: 60px;
          z-index: -1;

          @media only screen and (max-width: $tablet-bp) {
            font-size: 55px;
            top: 32px;
            left: 50px;
          }

          @media only screen and (min-width: $big-bp) {
            font-size: 95px;
            left: 70px;
          }
        }

        .coin__symbol {
          margin-left: 5px;
          height: 15px;
          padding: 4px 8px 0px 8px;
          background-color: $grey-3;
          border-radius: 5px;
          text-transform: uppercase;
          font-size: 8px;
          font-weight: $medium;
          align-self: center;

          @media only screen and (min-width: $big-bp) {
            font-size: 10px;
            height: 16px;
            margin-left: 7px;
          }
        }
      }
      .coin__rank {
        font-size: 8px;
        height: 15px;
        background-color: $grey-4;
        padding: 4px 4px 0px 4px;
        color: $white;
        border-radius: 5px;
        font-weight: $medium;
        width: 50px;
        text-align: center;
        margin-top: 20px;

        @media only screen and (min-width: $big-bp) {
          font-size: 10px;
          height: 20px;
          width: 70px;
          padding: 6px 4px 0px 4px;
        }
      }

      .coin__links {
        display: flex;
        flex-direction: row;
        margin-top: 15px;
        flex-wrap: wrap;

        @media only screen and (min-width: $big-bp) {
          margin-top: 20px;
        }

        .link {
          display: flex;
          justify-content: space-around;
          font-size: 10px;
          height: 25px;
          background-color: $grey-3;
          width: 100px;
          border-radius: 5px;
          cursor: pointer;
          font-weight: $medium;
          padding: 0 3px 0 3px;
          margin-right: 10px;
          margin-bottom: 5px;
          color: $black;

          @media only screen and (min-width: $big-bp) {
            height: 30px;
            font-size: 12px;
            width: 130px;
          }

          &:hover {
            background-color: $main-blue;
            color: $white;

            .link__click, .link__icon {
              fill: $white;
            }
          }

          .link__icon {
            width: 12px;
            height: 12px;
            align-self: center;
            transform: rotate(-45deg);

            @media only screen and (min-width: $big-bp) {
              width: 15px;
              height: 15px;
            }
          }
          
          .link__click {
            width: 10px;
            height: 10px;
            align-self: center;
            fill: $grey-4;

            @media only screen and (min-width: $big-bp) {
              width: 12px;
              height: 12px;
            }
          }

          .link__name {
            align-self: center;
          }
        }
      }

      .main-side {
        position: absolute;
        top: 50px;
        right: 0;

        @media only screen and (max-width: $mobile-bp) {
          position: relative;
          top: 30px;
        }

        .price__name {
          font-size: 10px;
          text-align: right;
          color: $table-blue;
          font-weight: $medium;

          @media only screen and (max-width: $mobile-bp) {
            text-align: left;
            margin-bottom: 5px;
          }

          @media only screen and (min-width: $big-bp) {
            font-size: 15px;
          }
        }

        .price {
          display: flex;
          flex-direction: row;

           .price__amount {
             font-size: 40px;
             font-weight: $bold;

             @media only screen and (min-width: $big-bp) {
              font-size: 50px;
            }
           }

           .price__change {
             width: 60px;
             height: 25px;
             align-self: center;
             border-radius: 3px;
             margin-left: 20px;

             @media only screen and (min-width: $big-bp) {
              height: 30px;
              width: 65px;
            }

             .price__change__amount {
               font-size: 12px;
               color: $white;
               text-align: center;
               align-self: center;
               margin-top: 4px;
               font-weight: $medium;

               @media only screen and (min-width: $big-bp) {
                margin-top: 5px;
                font-size: 15px;
              }
             }
           }

          .price__change--green {
            background-color: $green;
          }

          .price__change--red {
            background-color: $red;
          }
        }
        .price__sats {
          text-align: right;
          color: $grey-4;
          font-size: 12px;
          font-weight: $medium;
          margin-bottom: 5px;

          @media only screen and (max-width: $mobile-bp) {
            text-align: left;
            margin-top: 5px;
          }

          @media only screen and (min-width: $big-bp) {
            font-size: 15px;
          }
        }

        .price__high, .price__low, .price__ATH {
          font-size: 10px;
          display: flex;
          justify-content: flex-end;
          font-weight: $medium;

          @media only screen and (max-width: $mobile-bp) {
            justify-content: flex-start;
            margin-bottom: 2px;
          }

          @media only screen and (min-width: $big-bp) {
            font-size: 12px;
          }

          .price--color {
            color: $table-blue;
            margin-right: 5px;
          }
        }
      }
    }
  }
}